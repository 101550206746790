import React from "react";
import {Container} from './styles';


function Index(props:any) {

  return (
    <Container>
        404
    </Container>
  );
}

export default Index;