import React from "react";
import {Container} from './styles';
import content from '../../../Util/png/clube-smiles.png';

function Index() {

  return (
    <Container src={content}/>
  );
}

export default Index;